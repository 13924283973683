
.nav-item-icon {
  margin-left: 6px;
  margin-right: 6px;
}

.nav-main-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.walletcard {
  color: white;
  position: relative;
  margin-top: 11px;
}

html, body {
  font-family: 'Shadows Into Light', cursive;
  height: 100%;
  font-size:x-large;
}

body {
  color: white;
  background-repeat: no-repeat,no-repeat,no-repeat;
  background-size: 100% auto,100% auto,auto;
    background-position: 0 11%,0 70%,0 0;
}


.mint {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}


.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04AA6D;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}


.img-thumbnail {
  position: relative;
  width: 25%;
}

img {
  width: 25%;
  height: auto;
  opacity: 1;
}

.break {
  margin-top: 150px;
}

.btn-xl {
  padding: 10px 65px;
  font-size: 20px;
  border-radius: 10px;
}

.row {
  top: 35px;
  position: relative;
}

.mint-btn {
  display: flex;
  justify-content: center;
  padding-top: 1%;
}

.mint-btn1 {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.roadmap1 {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.roadmap2 {
  position: relative;
  margin-top: 50px;

}

.about {
  position: relative;
  margin-top: 50px;
  margin-bottom: 100px;
}

.footer-icon {
  display:flex;
  justify-content: center;
  align-items: center;
}

.about {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.appbar {
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(61, 66, 75, 0.07);
  width: 100%;
  /* height: 100%; */
  /* padding: 6px 7%; */
  display: flex;
  align-items: center;
  top: 0;
  position: sticky;
  background-color: hsla(0, 0%, 100%, 0);
  position: fixed;
  z-index: 10000;
}

.navbar-wrapper {
  width: 100%;
  padding: 6px 7%;
  display: flex;
  justify-content: space-between;
}

.nav-menu {
  display: flex;
  font-size: 15px;
  color: black;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.footer-nav-menu {
  display: flex;
  font-size: 15px;
  color: black;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-Left: 0px;
  list-style-type: none;
}



.nav-menu-container {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.nav-item {
  padding: 20px 12px;
  align-self: center;
  text-decoration: none !important;
  color: black;
  font-size: 18px;
}

.nav-item:hover,
.drawer-item h3 a:hover,
.drawer-social-container a:hover {
  animation: highlightColor 1s linear infinite !important;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo {
  margin-right: 44px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* color: #e8eaed; */
  color: #3d3d3d;
  font-family: Bleach;
}

.team-item-container {
  text-align: center;
  margin: 20px 0px !important;
}

.team-img {
  border: 5px solid #58805a;
  border-radius: 65px;
  width: 90%;
  height: auto;
}

.team-desc {
  text-align: center;
}

.team-desc p {
  font-size: 25px;
  margin: 0px !important;
}

.team-desc h3 {
  font-size: 30px;
  margin-bottom: 0px !important;
}

.hamburger-react {
  color: #3d3d3d;
}

.drawer {
  background-size: cover;
  background-position: 50%;
  min-width: 100%;
  padding-top: 100px;
}

.drawer-item {
  display: flex!important;
  justify-content: center!important;
}

.drawer-item h3 a {
  color: #3d3d3d;
  font-size: 25px;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  margin: auto;
  -webkit-justify-content: center !important;
  text-decoration: none;
}

.drawer-social-container {
  display: flex;
  justify-content: center;
}

.drawer-social-container a {
  width: 50px;
  text-align: center;
  font-size: 40px;
  margin: 15px;
  cursor: pointer;
}

.drawer-social-container a div {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.drawer-social-container a div svg {
  height: 40px;
  width: 40px;
}


@media (min-width: 1000px) {
  .hamburger-react {
    display: none;
  }
}

@media (max-width: 1000px) {
  .nav-menu {
    display: none;
  }
}

@media(max-width:900px){
  .grid-desc{
    margin-top: 100px;
  }

  .grid-roadmap{
    margin-top: 100px;
  }
}

@keyframes highlightColor {
  from,
  to {
    color: rgb(241, 84, 56);
  }
}

.mid {
  /* margin-top: 75px; */
  display:flex;
  justify-content: center;
  align-items: center;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-title {
  font-size: medium;
  display: flex;
  justify-content: center;
  align-items: center;
}


.gradient-text {
  /* Fallback: Set a background color. */
  background-color: #ca4246;
  font-size: 50px;

  /* Create the gradient. */
  background-image: linear-gradient(
    45deg,
    #0b0303 16.666%,
    #12d45c 16.666%,
    #0a9f19 33.333%,
    #249c00 33.333%,
    #0aa86e 50%,
    #000000 50%,
    #00000000 66.666%,
    #569847 66.666%,
    #479848 83.333%,
    #48a761 83.333%
  );

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Animate the text when loading the element. */
  /* This animates it on page load and when hovering out. */
  animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
}

.gradient-text:hover {
  animation: rainbow-text-simple-animation 0.5s ease-in forwards;
}

.gradient-name {
  /* Fallback: Set a background color. */
  background-color: #ca4246;
  font-size: 25px;

  /* Create the gradient. */
  background-image: linear-gradient(
    45deg,
    #030b08 16.666%,
    #12d42c 16.666%,
    #0a9f25 33.333%,
    #00159c 33.333%,
    #5ca80a 50%,
    #000000 50%,
    #000000 66.666%,
    #479859 66.666%,
    #479863 83.333%,
    #48a764 83.333%
  );

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Animate the text when loading the element. */
  /* This animates it on page load and when hovering out. */
  animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
}

.gradient-name:hover {
  animation: rainbow-text-simple-animation 0.5s ease-in forwards;
}

@keyframes rainbow-text-simple-animation-rev {
  0% {
    background-size: 650%;
  }
  40% {
    background-size: 650%;
  }
  100% {
    background-size: 100%;
  }
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-simple-animation {
  0% {
    background-size: 100%;
  }
  80% {
    background-size: 650%;
  }
  100% {
    background-size: 650%;
  }
}

.borpaLick {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.connect-btn {
  margin-left: 33%;
}